import React, { useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import { useStaticQuery, graphql } from 'gatsby';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      FirdsPagesGallery: allFile(filter: { relativeDirectory: { eq: "galery/images/birds" } }) {
        nodes {
          childImageSharp {
            sizes {
              srcWebp
              src
              presentationWidth
              presentationHeight
              originalName
            }
          }
        }
      }
    }
  `);
  return data.FirdsPagesGallery.nodes;
};

const Birds = () => {
  const data = useHousingImg();

  const images = data.map(
    ({
      childImageSharp: {
        sizes: { srcWebp, src, originalName, presentationWidth, presentationHeight },
      },
    }) => ({
      src: srcWebp,
      original: src,
      width: presentationWidth,
      height: presentationHeight,
      caption: originalName,
    }),
  );

  return (
    <section className="wrapper white" style={{  display: 'flex' }}>
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__zoomIn">{'Avistamiento de Aves y otras especies'}</h2>
        </header>
        <div className={`feature wow animate__animated animate__bounce animate__slideInUp`} >
          <Gallery images={images} enableImageSelection={false} />
        </div>
      </div>
     
    </section>
  );
};

export default Birds;
