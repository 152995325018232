import React, { useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import { useStaticQuery, graphql } from 'gatsby';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      FloraAndFaunaPagesGallery: allFile(filter: { relativeDirectory: { eq: "galery/images/flora-and-fauna" } }) {
        nodes {
          childImageSharp {
            sizes {
              srcWebp
              src
              presentationWidth
              presentationHeight
              originalName
            }
          }
        }
      }
    }
  `);
  return data.FloraAndFaunaPagesGallery.nodes;
};

const FloraAndFauna = () => {

  const data = useHousingImg();

  const images = data.map(
    ({
      childImageSharp: {
        sizes: { srcWebp, src, originalName, presentationWidth, presentationHeight },
      },
    }) => ({
      src: srcWebp,
      original: src,
      width: presentationWidth,
      height: presentationHeight,
      caption: originalName,
    }),
  );

  return (
    <section className=" wrapper white" style={{ display: 'flex' }}>
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__zoomIn">{'Flora y fauna'}</h2>
        </header>
        <Gallery images={images} enableImageSelection={false} />
      </div>
    </section>
  );
};

export default FloraAndFauna;
